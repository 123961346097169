<div class="section">
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-12">
        <h1 class="title font-weight-bold text-dark text-center">
          {{ 'risk.riskWarning' | translate }}
        </h1>
      </div>
    </div>
  </div>

  <div class="container-fluid tw-pt-[30px]">
    <div class="row">
      <div class="col-11 mx-auto col-md-8 offset-md-2 tw-pb-8">
        <strong class="tw-text-black">{{ 'risk.tableOfContents' | translate }}</strong>
        <ol class="tw-pl-5">
          <li>
            <a routerLink="." fragment="ecf" class="table-content__link !tw-underline">{{
              'risk.riskWarningTitle' | translate
            }}</a>
          </li>
          <li>
            <a routerLink="." fragment="tcf" class="table-content__link !tw-underline">{{
              'riskIeo.riskWarningTitle' | translate
            }}</a>
          </li>
        </ol>
      </div>

      <section id="ecf" autoscroll="true" class="col-11 mx-auto col-md-8 offset-md-2">
        <h2 class="tw-text-3xl tw-font-bold tw-uppercase">
          {{ 'risk.riskWarningTitle' | translate }}
        </h2>
        <p>{{ 'risk.riskWarningDesc1' | translate }}</p>
        <p>{{ 'risk.riskWarningDesc2' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.businessRisk' | translate }}</h3>
        <p>{{ 'risk.businessRiskDesc' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.illiquidity' | translate }}</h3>
        <p>{{ 'risk.illiquidityDesc' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.returns' | translate }}</h3>
        <p>{{ 'risk.returnsDesc' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.dilution' | translate }}</h3>
        <p>{{ 'risk.dilutionDesc' | translate }}</p>
        <h3 class="subtitle">
          {{ 'risk.spreadYourRisks' | translate }}; {{ 'risk.researchWell' | translate }}
        </h3>
        <p>{{ 'risk.spreadYourRisksDesc' | translate }}</p>
      </section>
    </div>
  </div>

  <hr />

  <div class="container-fluid">
    <div class="row">
      <section id="tcf" autoscroll="true" class="col-11 mx-auto col-md-8 offset-md-2">
        <h2 class="tw-text-3xl tw-font-bold tw-uppercase">
          {{ 'riskIeo.riskWarningTitle' | translate }}
        </h2>
        <p>{{ 'riskIeo.riskWarningDesc1' | translate }}</p>
        <p>{{ 'riskIeo.riskWarningDesc2' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.businessRisk' | translate }}</h3>
        <p>{{ 'riskIeo.businessRiskDesc' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.illiquidity' | translate }}</h3>
        <p>{{ 'riskIeo.illiquidityDesc' | translate }}</p>
        <h3 class="subtitle">{{ 'risk.returns' | translate }}</h3>
        <p>{{ 'riskIeo.returnsDesc' | translate }}</p>
        <h3 class="subtitle">
          {{ 'risk.spreadYourRisks' | translate }}; {{ 'risk.researchWell' | translate }}
        </h3>
        <p>{{ 'riskIeo.spreadYourRisksDesc' | translate }}</p>
      </section>
    </div>
  </div>
</div>
