<section class="section section-shaped">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <h2 class="text-center font-weight-extrabold mb-4">{{ 'common.login' | translate }}</h2>
        <form (ngSubmit)="submit()" [formGroup]="form" [class.show-form-error]="showFormError">
          <div class="mb-3">
            <input
              class="form-control"
              placeholder="{{ 'common.email' | translate }}"
              formControlName="email"
              type="text"
              id="user_email"
            />
          </div>

          <div class="mb-3">
            <div class="password-panel">
              <input
                class="form-control"
                [attr.type]="showPassword ? 'text' : 'password'"
                placeholder="{{ 'login.password' | translate }}"
                formControlName="password"
                id="user_password"
                maxlength="30"
              />
              <div class="show-password" (click)="toggle()">
                <i
                  class="fa"
                  [class.fa-eye-slash]="!showPassword"
                  [class.fa-eye]="showPassword"
                ></i>
              </div>
            </div>
          </div>

          <div class="btn-panel">
            <button type="submit" class="btn btn-primary tw-w-full">
              {{ 'common.login' | translate }}
            </button>
          </div>

          <div class="row mt-3 forgot-password-panel">
            <div class="col-6">
              <mat-checkbox formControlName="isRememberMe">
                {{ 'login.rememberMe' | translate }}
              </mat-checkbox>
            </div>
            <div class="col-6 text-right">
              <a routerLink="/forgot-password">{{ 'login.forgotPassword' | translate }}</a>
            </div>
          </div>
        </form>
        <!-- <div class="login-with mb-4 mt-5"><span>{{ 'login.orLoginWith' | translate }}</span></div>
                <div class="d-flex justify-content-center">
                    <div class="social-media">
                        <img alt="pitchIN Facebook Login" src="../../../assets/img/icons/common/facebook_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Facebook)" />
                    </div>
                    <div class="social-media">
                        <img alt="pitchIN Twitter Login" src="../../../assets/img/icons/common/twitter_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Twitter)" />
                    </div>
                    <div class="social-media">
                        <img alt="pitchIN Google Login" src="../../../assets/img/icons/common/google_icon_circle@2x.png"
                            (click)="signInOAuth(OAuthType.Google)" />
                    </div>
                </div> -->
        <hr />
        <h5 class="font-weight-bold text-center mt-3">
          {{ 'login.dontHaveAccount' | translate }}
          <a routerLink="/sign-up" class="font-weight-extrabold">{{
            'login.signUpNow' | translate
          }}</a>
        </h5>
      </div>
    </div>
  </div>
</section>
