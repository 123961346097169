<div class="section !tw-pb-0">
  <div class="container-fluid">
    <div
      class="tw-rounded-3xl tw-border tw-border-divider tw-bg-gradient-to-br tw-from-white tw-to-gray-50 tw-p-10 md:tw-border-none md:tw-to-white md:tw-p-0"
    >
      <div
        class="tw-overflow-hidden md:tw-mb-0 md:tw-rounded-3xl md:tw-border md:tw-border-divider md:tw-bg-gradient-to-br md:tw-from-white md:tw-to-gray-50 md:tw-p-10"
      >
        <div class="tw-mb-4 tw-max-w-xl">
          <div class="p-text-preheader tw-mb-1 tw-text-lg !tw-font-extralight">
            {{ 'landing.partnerPreheader' | translate }}
          </div>
          <h2
            class="p-text-h2 !tw-font-normal"
            [innerHTML]="'landing.partnerDescription' | translate"
          ></h2>
        </div>
        <div class="partner lg:tw-flex lg:tw-gap-4 lg:tw-overflow-x-scroll">
          <a
            [routerLink]="['/partner', 'women-in-tech']"
            class="partner-item tw-mx-auto tw-mb-4 tw-block tw-bg-[#120E95] tw-p-8 tw-text-white hover:tw-bg-[#120E95f3] hover:tw-text-white lg:tw-mb-0 lg:tw-p-10"
          >
            <div class="tw-w-36 tw-max-w-[80%]">
              <img
                class="tw-w-full"
                src="/assets/img/hero/women-in-tech-logo.png"
                alt="Women in Tech"
              />
            </div>
            <p class="p-text-p tw-mt-4">{{ 'landing.partnerWomenInTech' | translate }}</p>
            <div class="p-text-p tw-mt-5">
              <i
                class="fa fa-chevron-circle-right tw-mr-2 tw-text-white tw-text-opacity-90"
                aria-hidden="true"
              ></i>
              <span class="tw-text-white tw-text-opacity-70">{{
                'landing.partnerCta' | translate: { partner: 'Women in Tech&#174;' }
              }}</span>
            </div>
          </a>
          <a
            [routerLink]="['/partner', 'watchtowerfriends']"
            class="partner-item tw-mx-auto tw-mb-4 tw-block tw-bg-[#88121E] tw-p-8 tw-text-white hover:tw-bg-[#88121Ef3] hover:tw-text-white lg:tw-mb-0 lg:tw-p-10"
          >
            <div class="tw-w-36 tw-max-w-[80%]">
              <img
                class="tw-w-full"
                src="/assets/img/hero/wtf-logo-mobile.png"
                alt="Watchtower and Friends"
              />
            </div>
            <p class="p-text-p tw-mt-4">{{ 'landing.watchTowerAndFriends' | translate }}</p>
            <div class="p-text-p tw-mt-5">
              <i
                class="fa fa-chevron-circle-right tw-mr-2 tw-text-white tw-text-opacity-90"
                aria-hidden="true"
              ></i>
              <span class="tw-text-white tw-text-opacity-70">{{
                'landing.partnerCta' | translate: { partner: 'WatchTower &amp; Friends' }
              }}</span>
            </div>
          </a>
          <a
            [routerLink]="['/partner', 'beyond4']"
            class="partner-item tw-mx-auto tw-mb-4 tw-block tw-bg-[#0f284f] tw-p-8 tw-text-white hover:tw-bg-[#0f284ff3] hover:tw-text-white lg:tw-mb-0 lg:tw-p-10"
          >
            <div class="tw-w-36 tw-max-w-[80%]">
              <img class="tw-w-full" src="/assets/img/hero/beyond4-logo.png" alt="BEYOND4" />
            </div>
            <p class="p-text-p tw-mt-4">{{ 'landing.partnerBeyond4' | translate }}</p>
            <div class="p-text-p tw-mt-5">
              <i
                class="fa fa-chevron-circle-right tw-mr-2 tw-text-white tw-text-opacity-90"
                aria-hidden="true"
              ></i>
              <span class="tw-text-white tw-text-opacity-70">{{
                'landing.partnerCta' | translate: { partner: 'BEYOND4' }
              }}</span>
            </div>
          </a>
          <a
            [routerLink]="['/partner', 'mranti']"
            class="partner-item tw-mx-auto tw-mb-4 tw-block tw-bg-[#0000ff] tw-p-8 tw-text-white hover:tw-bg-[#0000ffe3] hover:tw-text-white lg:tw-mb-0 lg:tw-p-10"
          >
            <div class="tw-w-36 tw-max-w-[80%]">
              <img class="tw-w-full" src="/assets/img/hero/mranti-logo.png" alt="Mranti" />
            </div>
            <p class="p-text-p tw-mt-4">{{ 'landing.partnerMranti' | translate }}</p>
            <div class="p-text-p tw-mt-5">
              <i
                class="fa fa-chevron-circle-right tw-mr-2 tw-text-white tw-text-opacity-90"
                aria-hidden="true"
              ></i>
              <span class="tw-text-white tw-text-opacity-70">{{
                'landing.partnerCta' | translate: { partner: 'MRANTI' }
              }}</span>
            </div>
          </a>

          <a
            [routerLink]="['/partner', 'broku']"
            class="partner-item tw-mx-auto tw-block tw-bg-[#000000] tw-p-8 tw-text-white hover:tw-bg-[#292929] hover:tw-text-white lg:tw-p-10"
          >
            <div class="tw-w-36 tw-max-w-[80%]">
              <img class="filter tw-w-full" src="/assets/img/hero/broku-logo.png" alt="Broku" />
            </div>
            <p class="p-text-p tw-mt-4">{{ 'landing.partnerBroku' | translate }}</p>
            <div class="p-text-p tw-mt-5">
              <i
                class="fa fa-chevron-circle-right tw-mr-2 tw-text-white tw-text-opacity-90"
                aria-hidden="true"
              ></i>
              <span class="tw-text-white tw-text-opacity-70">{{
                'landing.partnerCta' | translate: { partner: 'BROKU' }
              }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
