// Credit to: https://itbusinesshub.com/blog/integrate-google-tag-manager-in-angular-app/

import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Role } from '../enum/role.enum';
import { WindowReferenceService } from './window-reference.service';
import * as Sentry from '@sentry/angular-ivy';
import { GtmCommerceData } from '../models/gtm/gtm-commerce-data';
import { GtmCommerceItem } from '../models/gtm/gtm-commerce-item';
@Injectable({
  providedIn: 'root',
})

/* -------------------------- +
NOTES:

WE USE THIS SERVICE TO SEND CUSTOM EVENTS TO GOOGLE TAG MANAGER.
ANY CLICK EVENTS WE UTILISE THE [data-t="*"]. 
THE * WILL BE THE EVENT IN CAMELCASE, EXAMPLE: data-t="pageView"

FOR MORE INFO, REFER TO PLT-831, PLT-800
+ --------------------------- */
export class GtmDataLayerService {
  private window;
  role: Role;

  constructor(
    private _windowRef: WindowReferenceService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {
    // intialise the window to what we get from our window service
    this.window = _windowRef.nativeWindow;
  }

  private pushData(obj) {
    if (obj && this.window.dataLayer && isPlatformBrowser(this.platform)) {
      // Convert the currency into ISO (which is consistent with IEO)
      if (obj.ecommerce?.currency === 'RM') obj.ecommerce.currency = 'MYR';

      if (obj.ecommerce?.payment_type === 'MaybankFPX') obj.ecommerce.payment_type = 'Fpx';

      this.window.dataLayer.push({ type: 'ecf', ...obj });
    }
  }

  logUser(userId?: string, userType?: number, hasUserEkyc?: boolean, userEmail?: string) {
    // currently log whenever login & logout, for those has session remains in browser
    // would also be logged via navbarComponent
    const hit = {
      'user_id': userId,
      'user_type': Role[userType],
      'user_ekyc': hasUserEkyc,
      'user_email': userEmail,
    };
    this.pushData(hit);

    // send the email data to `Sentry` to identify error easily
    if (userEmail) Sentry.setUser({ email: userEmail });
    else {
      Sentry.setUser(null);
      this.logLogout();
    }
  }

  logPageView(pageUrl: string, pageTitle: string, contentType?: string, contentGroup?: string) {
    // content_type: to flag campaign types ["Live", "Pre-Live", "Completed", "Details"]
    // content_group: if landed in "All campaigns" page, eg: `/Busineeses`
    const hit = {
      'event': 'virtualPageView',
      'page_location': pageUrl,
      'page_title': pageTitle,
      'content_type': contentType,
      'content_group': contentGroup,
    };
    this.pushData(hit);
  }

  logEvent(eventName: string, eventParams?: object) {
    const hit = {
      'event': eventName,
      'event_params': eventParams,
    };
    this.pushData(hit);
  }

  logViewItem(commerceData: { items: GtmCommerceItem[] }) {
    const dataLayer = {
      event: 'view_item',
      ecommerce: commerceData,
    };

    this.pushData(dataLayer);
  }

  logAddToCart(commerceData: { items: GtmCommerceItem[] }) {
    const dataLayer = {
      event: 'add_to_cart',
      ecommerce: commerceData,
    };

    this.pushData(dataLayer);
  }

  logBeginCheckout(commerceData: GtmCommerceData) {
    const dataLayer = {
      event: 'begin_checkout',
      ecommerce: commerceData,
    };

    this.pushData(dataLayer);
  }

  logAddPaymentInfo(commerceData: GtmCommerceData) {
    const dataLayer = {
      event: 'add_payment_info',
      ecommerce: commerceData,
    };

    this.pushData(dataLayer);
  }

  logPurchase(commerceData: GtmCommerceData) {
    const dataLayer = {
      event: 'purchase',
      ecommerce: commerceData,
    };

    this.pushData(dataLayer);
  }

  logSignUp(userType: string) {
    const hit = {
      'event': 'sign_up',
      'user_type': userType,
    };
    this.pushData(hit);
  }

  logLogout() {
    this.logEvent('logout');
  }

  logFormBlur(inputName: string) {
    this.logEvent('formBlur', {
      input_name: inputName,
    });
  }

  logErrorMessage(errorCode: string) {
    const hit = {
      'event': 'errorMessage',
      'error_type': errorCode,
    };
    this.pushData(hit);
  }
}
