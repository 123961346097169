<div class="tw-bg-[#0000ff]">
  <img
    src="./assets/img/hero/mranti-pitchin.png"
    class="tw-mx-auto tw-block tw-w-full tw-max-w-[1280px]"
    alt="pitchIN x MRANTI"
  />
</div>

<section class="tw-bg-gray-100 tw-py-8">
  <div class="container">
    <div class="md:tw-grid md:tw-grid-cols-5 md:tw-items-center md:tw-gap-2">
      <div class="md:tw-col-span-2">
        <picture>
          <source
            media="(max-width: 767px)"
            srcset="./assets/img/hero/mranti-pitchin-logos-horizontal.png"
          />
          <source media="(min-width: 768px)" srcset="./assets/img/hero/mranti-pitchin-logos.png" />
          <img
            src="./assets/img/hero/mranti-pitchin-logos.png"
            alt="MRANTI - pitchIN"
            class="tw-mx-auto tw-block tw-w-full sm:tw-max-w-[90%] lg:tw-max-w-[80%]"
          />
        </picture>
      </div>

      <p class="tw-mb-0 md:tw-col-span-3">
        MRANTI and pitchIN are proud to announce a new partnership aimed at empowering and
        accelerating early and mid-stage startups in Malaysia. MRANTI, a Malaysia’s central research
        commercialisation agency, will now be supporting its startups to raise funds via equity
        crowdfunding on pitchIN. This partnership will provide investors with a unique opportunity
        to support and invest in innovative and exciting startups in the region.
      </p>
    </div>
  </div>
</section>

<!-- Initial loading -->
<section class="section" *ngIf="totalCampaigns == null">
  <div class="container-fluid">
    <agmo-loading></agmo-loading>
  </div>
</section>

<!-- Section: Coming soon (when no campaign exist) -->
<section class="section" *ngIf="totalCampaigns != null && totalCampaigns == 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5 tw-text-center">
      {{ 'common.investmentOpportunities' | translate }}
    </h2>
    <div class="tw-text-center">
      <div class="tw-my-10 tw-text-lg">{{ 'common.comingSoon' | translate }}</div>
    </div>
  </div>
</section>

<!-- Section: Live -->
<section class="section" *ngIf="list[CampaignStatus.Live].total > 0">
  <div class="container-fluid">
    <h2 class="font-weight-extrabold tw-mb-5">
      {{ 'common.liveOpportunities' | translate }}
    </h2>
    <app-campaign-list
      [campaigns]="list[CampaignStatus.Live].campaigns"
      [params]="list[CampaignStatus.Live].params"
      [total]="list[CampaignStatus.Live].total"
      [isLoading]="list[CampaignStatus.Live].isLoading"
      [noRecordText]="'common.comingSoon'"
      (getCampaignList)="getList($event, CampaignStatus.Live)"
    ></app-campaign-list>
  </div>
</section>

<!-- Section: Pre-Live -->
<ng-container *ngIf="list[CampaignStatus.PreLive].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5">
        {{ 'common.preLiveCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.PreLive].campaigns"
        [params]="list[CampaignStatus.PreLive].params"
        [total]="list[CampaignStatus.PreLive].total"
        [isLoading]="list[CampaignStatus.PreLive].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.PreLive)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<!-- Section: Success -->
<ng-container *ngIf="list[CampaignStatus.Success].total > 0">
  <hr class="tw-my-0" />

  <section class="section">
    <div class="container-fluid">
      <h2 class="font-weight-extrabold tw-mb-5">
        {{ 'common.successCampaigns' | translate }}
      </h2>
      <app-campaign-list
        [campaigns]="list[CampaignStatus.Success].campaigns"
        [params]="list[CampaignStatus.Success].params"
        [total]="list[CampaignStatus.Success].total"
        [isLoading]="list[CampaignStatus.Success].isLoading"
        [noRecordText]="'common.comingSoon'"
        (getCampaignList)="getList($event, CampaignStatus.Success)"
      ></app-campaign-list>
    </div>
  </section>
</ng-container>

<div class="section--partner tw-bg-[#f3f3f3] tw-py-14 tw-text-opacity-80">
  <div class="container tw-max-w-3xl">
    <h3 class="tw-mb-4 tw-text-center tw-font-bold tw-text-[#0000FF]">About MRANTI</h3>

    <p>
      The Malaysian Research Accelerator for Technology and Innovation (MRANTI), a convergence of
      Technology Park Malaysia (TPM) Corporation Sdn Bhd and the Malaysian Global Innovation and
      Creativity Centre (MaGIC), is Malaysia’s central research commercialisation agency that
      fast-tracks the development of technology innovations from ideas to impact.
    </p>

    <p>
      MRANTI serves as a connector, incubator and catalyst to enable early-stage ideation to mature
      entities to commercialise and scale. MRANTI offers innovators and industry access to
      world-class integrated infrastructure, interventions & programmes, partnership and a suite of
      resources. In doing so, MRANTI aims to expand Malaysia’s funnel of innovation supply, and
      unlock new R&D value by ensuring effective transitions in the commercialisation lifecycle. It
      will also link academia with industry and the public sector to streamline market-driven R&D
      efforts for mission-based outcomes.
    </p>

    <p>
      MRANTI is headquartered at MRANTI Park, an extensive 686 acre 4IR innovation hub in Kuala
      Lumpur, is supporting the growth of smart manufacturing, biotech, agritech, smart city, green
      tech and enabling technology clusters.
    </p>

    <div class="tw-mt-7 tw-text-center">
      <h5 class="tw-mb-3 tw-font-medium tw-text-black">Connect with us</h5>
      <a
        href="https://mranti.my/"
        target="_blank"
        class="btn tw-rounded-full tw-bg-[#0000FF] tw-text-white hover:tw-text-[#263E7E]"
      >
        More on MRANTI
      </a>
    </div>
  </div>
</div>
