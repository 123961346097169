<!-- Connect with Founders -->
<div
  *ngIf="campaignInfo?.foundersUrl"
  class="tw-mb-5 md:tw-mb-14"
  [class]="!isMobile ? 'tw-hidden md:tw-block' : ''"
>
  <button
    type="button"
    (click)="collapse3.toggle()"
    [attr.aria-expanded]="!isConnectWithFoundersCollapsed"
    aria-controls="collapseInvestSummary"
    [class]="isMobile ? 'btn btn-light-grey collapsible-btn p-accordion-header' : 'section-header'"
  >
    <h2 class="p-text-sidebar-header tw-mb-0 lg:tw-mb-4">
      {{ 'campaign.connectWithFounders' | translate }}
    </h2>
    <i
      class="fa !tw-ml-auto tw-text-2xl md:tw-hidden"
      [class]="isConnectWithFoundersCollapsed ? 'fa-caret-down' : 'fa-caret-up'"
    ></i>
  </button>
  <div
    class="tw-pt-4 md:tw-pt-0"
    [class.tw-pl-4]="isMobile"
    #collapse3="ngbCollapse"
    [(ngbCollapse)]="isConnectWithFoundersCollapsed"
  >
    <ng-container *ngIf="isLogin; else goToLogin">
      <a
        [href]="campaignInfo?.foundersUrl"
        target="_blank"
        class="tw-flex tw-flex-row tw-gap-1 tw-font-semibold tw-text-link tw-underline"
        data-t="connectWithFounders"
        data-t-location="sidebar"
      >
        <div class="pitchin-icon pitchin-icon-calendar"></div>
        {{ 'campaign.scheduleACall' | translate }}
      </a>
    </ng-container>
    <ng-template #goToLogin>
      <div
        class="tw-flex tw-cursor-pointer tw-flex-row tw-gap-1 tw-font-semibold tw-text-link tw-underline hover:tw-text-primary-dark hover:tw-no-underline"
        (click)="handleLoginToProceed()"
      >
        <div class="pitchin-icon pitchin-icon-calendar"></div>
        {{ 'campaign.scheduleACall' | translate }}
      </div>
    </ng-template>
  </div>
</div>

<!-- Investment summary -->
<div class="tw-mb-5 md:tw-mb-14" [class]="!isMobile ? 'tw-hidden md:tw-block' : ''">
  <button
    type="button"
    (click)="collapse1.toggle()"
    [attr.aria-expanded]="!isInvestSummaryCollapsed"
    aria-controls="collapseInvestSummary"
    [class]="isMobile ? 'btn btn-light-grey collapsible-btn p-accordion-header' : 'section-header'"
  >
    <h2 class="p-text-sidebar-header tw-mb-0 lg:tw-mb-4">
      {{ 'campaign.investmentSummary' | translate }}
    </h2>
    <i
      class="fa !tw-ml-auto tw-text-2xl md:tw-hidden"
      [class]="isInvestSummaryCollapsed ? 'fa-caret-down' : 'fa-caret-up'"
    ></i>
  </button>

  <div
    class="tw-pt-4 md:tw-pt-0"
    [class.tw-pl-4]="isMobile"
    #collapse1="ngbCollapse"
    [(ngbCollapse)]="isInvestSummaryCollapsed"
  >
    <ul class="tw-list-none tw-space-y-4 tw-pl-0">
      <li *ngFor="let item of investmentStats">
        <label class="overview-label">{{ item.name | translate }}</label>

        <div class="overview-value">
          <ng-container *ngIf="item.isCurrency; else generalValue">
            {{ item.value | numberSuffix }}
          </ng-container>
          <ng-template #generalValue>
            {{ item.value }}
          </ng-template>
        </div>
      </li>
    </ul>

    <!-- To show lead investor or any highlights -->
    <div
      *ngIf="campaign.highlightTitle || campaign.highlightBody"
      class="tw-mb-5 tw-rounded tw-bg-gray-100 tw-px-3 tw-py-2 tw-text-sm tw-leading-tight tw-shadow-inner"
    >
      <div class="tw-flex">
        <i
          *ngIf="campaign.highlightTitle"
          class="fa fa-info-circle tw-mr-2 !tw-text-sm"
          aria-hidden="true"
        ></i>
        <div class="tw-shrink tw-overflow-hidden tw-break-words">
          <div *ngIf="campaign.highlightTitle" class="tw-mb-0.5">
            {{ campaign.highlightTitle }}
          </div>
          <ng-container *ngIf="campaign.highlightBody">
            <div class="tw-font-semibold">
              <a
                *ngIf="campaign.highlightUrl"
                class="tw-text-body hover:tw-text-body-dark"
                [href]="campaign.highlightUrl"
                target="_blank"
              >
                {{ campaign.highlightBody }}
              </a>
              <span *ngIf="!campaign.highlightUrl">
                {{ campaign.highlightBody }}
              </span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Business overview -->
<div class="tw-mb-5 md:tw-mb-14" [class]="!isMobile ? 'tw-hidden md:tw-block' : ''">
  <button
    type="button"
    (click)="collapse2.toggle()"
    [attr.aria-expanded]="!isBusinessOverviewCollapsed"
    aria-controls="collapseBusinessOverview"
    [class]="isMobile ? 'btn btn-light-grey collapsible-btn p-accordion-header' : 'section-header'"
  >
    <h2 class="p-text-sidebar-header tw-mb-0 lg:tw-mb-4">
      {{ 'campaign.businessOverview' | translate }}
    </h2>
    <i
      class="fa !tw-ml-auto tw-text-2xl md:tw-hidden"
      [class]="isBusinessOverviewCollapsed ? 'fa-caret-down' : 'fa-caret-up'"
    ></i>
  </button>

  <div
    class="tw-pt-4 md:tw-pt-0"
    [class.tw-pl-4]="isMobile"
    #collapse2="ngbCollapse"
    [(ngbCollapse)]="isBusinessOverviewCollapsed"
  >
    <ul class="tw-list-none tw-space-y-4 tw-pl-0">
      <li>
        <label class="overview-label">{{ 'company.name' | translate }}</label>
        <div class="overview-value">
          {{ campaign.company?.name || '-' }}
        </div>
      </li>

      <li>
        <label class="overview-label">{{ 'campaign.location' | translate }}</label>
        <div class="overview-value">
          {{ campaign.company?.address || '-' }}
        </div>
      </li>

      <li>
        <label class="overview-label">{{ 'campaign.website' | translate }}</label>
        <div class="overview-value">
          <a
            class="tw-font-bold tw-underline"
            *ngIf="campaign.company?.websiteUrl; else empty"
            [href]="campaign.company?.websiteUrl | weblink"
            target="_blank"
          >
            {{ campaign.company?.websiteUrl | weblink: 'true' }}</a
          >
        </div>
      </li>

      <li>
        <label class="overview-label">{{ 'campaign.registrationNo' | translate }}</label>
        <div class="overview-value">
          {{ campaign.company?.registrationNumber || '-' }}
        </div>
      </li>

      <li>
        <label class="overview-label">{{ 'campaign.socialMedia' | translate }}</label>
        <div class="overview-value tw-flex tw-gap-1.5 tw-pt-2">
          <ng-container *ngFor="let social of campaign.company?.socialLinks">
            <a
              [href]="social.url | weblink"
              target="_blank"
              *ngIf="social.url"
              [title]="social.url | weblink"
            >
              <div
                class="pitchin-icon"
                [class.pitchin-icon-facebook]="social.type == SocialLinkType.Facebook"
                [class.pitchin-icon-twitter]="social.type == SocialLinkType.Twitter"
                [class.pitchin-icon-instagram]="social.type == SocialLinkType.Instagram"
                [class.pitchin-icon-linkedin]="social.type == SocialLinkType.LinkedIn"
                [class.pitchin-icon-tiktok]="social.type == SocialLinkType.TikTok"
              ></div>
            </a>
          </ng-container>
          <span
            *ngIf="
              !campaign.company ||
              !campaign.company.socialLinks ||
              campaign.company.socialLinks.length == 0
            "
            >-</span
          >
        </div>
      </li>
    </ul>
  </div>
</div>

<ng-template #empty>-</ng-template>
