import { Component, OnInit } from '@angular/core';
import { BusinessesService } from 'src/app/core/services/api/mysec/businesses.service';
import { ShareholderFilter } from '../../businesses/shareholder-filter';
import { ShareHolderOwnership } from 'src/app/core/models/mysec/share-holder-ownership';
import { DATE_FORMATS } from 'src/app/core/services/constant';
import { ShareOwnership } from 'src/app/core/enum/share-ownership.enum';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-shareholder-information',
  templateUrl: './shareholder-information.component.html',
  styleUrls: ['./shareholder-information.component.scss'],
})
export class ShareholderInformationComponent implements OnInit {
  displayColumns: string[] = ['Name', 'ShareOwnership', 'SharePercentage'];

  isLoading = true;
  total: number;
  totalShareholders: number;
  totalShares: number;

  tickerCodes: { key: string; value: string }[] = [];
  selectedTickerCode: string = 'All';

  params = new ShareholderFilter();
  shareholderList: ShareHolderOwnership[] = [];

  dateFormat = DATE_FORMATS.defaultDate;

  ShareOwnership: typeof ShareOwnership = ShareOwnership;

  constructor(private businessesService: BusinessesService) {}

  ngOnInit() {
    this.getShareholderList();
  }

  getShareholderList() {
    this.isLoading = true;

    this.businessesService
      .getShareholderList(this.params)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe((data) => {
        this.shareholderList = data.data;
        this.total = data.total;
        this.totalShareholders = data?.totalShareHolders;
        this.totalShares = data?.totalShares;
        this.initTickerCodes(data);
      });
  }

  initTickerCodes(data) {
    if (this.tickerCodes.length === 0) {
      const tickerCodeList = data.tickerCodes.map((item) => {
        return {
          key: item.key,
          value: item.value.toUpperCase(),
        };
      });

      this.tickerCodes = [
        {
          key: '',
          value: 'All',
        },
        ...tickerCodeList,
      ];
    }
  }

  handlePageChange() {
    this.getShareholderList();
  }

  handleFilter(paramKey: string, value: string) {
    this.params[paramKey] = value;
    this.params.currentPage = 1;
    this.getShareholderList();
  }

  handleFilterTickerCode(tickerCode: string) {
    this.selectedTickerCode = tickerCode;

    const getTickerCode = this.tickerCodes.find((t) => t.value === tickerCode);

    if (getTickerCode) {
      this.handleFilter('tickerCode', getTickerCode.key);
    }
  }
}
