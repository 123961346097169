<a class="campaign-card" [routerLink]="['/equity', campaign.slug]">
  <div class="campaign-card__head tw-relative">
    <div
      *ngIf="campaign.highlightTitle || campaign.highlightBody"
      class="tw-absolute tw-bottom-0 tw-z-[1] tw-w-full tw-bg-[#2f6a47] tw-py-1.5 tw-pl-7 tw-text-xs tw-leading-none tw-text-white"
    >
      <div class="tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap tw-pr-[110px]">
        <span *ngIf="campaign.highlightTitle">
          {{ campaign.highlightTitle }}<span *ngIf="campaign.highlightBody" class="tw-px-1">-</span>
        </span>
        <span *ngIf="campaign.highlightBody">{{ campaign.highlightBody }}</span>
      </div>
    </div>
    <div class="campaign-card__top-badge">
      <div
        class="status-badge badge--icon tw-bg-private-light tw-text-private"
        *ngIf="campaign?.isPrivate && !campaign?.isInvited"
      >
        <p-icon class="badge-icon" name="lock" [iconClass]="'tw-w-4 tw-h-4'"></p-icon>
        {{ 'common.inviteOnly' | translate }}
      </div>
      <div
        class="status-badge badge--icon badge--highlight tw-bg-private-lightest tw-text-private"
        *ngIf="campaign?.isPrivate && campaign?.isInvited"
      >
        <p-icon class="badge-icon" name="unlock" [iconClass]="'tw-w-4 tw-h-4'"></p-icon>
        {{ 'common.invited' | translate }}
      </div>
      <div class="status-badge success-background" *ngIf="campaign.isAngelTaxIncentive">ATI</div>
      <div
        class="status-badge tw-bg-[#004e5f] tw-text-white tw-text-opacity-80"
        *ngIf="campaign.isShariah"
      >
        <i class="status-badge-icon pitchin-icon-shariah tw-mr-0.5 tw-align-top"></i>
        {{ 'company.shariahCompliant' | translate }}
      </div>
    </div>
    <div class="campaign-card__banner tw-relative">
      <img
        [src]="campaign.company?.companyBannerUrl || defaultBanner"
        [alt]="campaign.company?.brandName"
      />
    </div>
  </div>

  <div class="campaign-card__body">
    <div class="tw-flex tw-gap-2">
      <div class="campaign-card__title p-text-emphasis tw-line-clamp-1">
        {{ campaign.company?.brandName }}
      </div>
      <div class="campaign-card__logo">
        <img
          class="!tw-object-cover"
          [src]="campaign.company?.companyLogoUrl || defaultLogo"
          [alt]="campaign.company?.brandName"
        />
      </div>
    </div>
    <div class="campaign-card__desc p-text-body tw-line-clamp-3">
      {{ campaign.company?.tagline }}
    </div>
    <app-tag-list
      [tags]="campaign.company?.sectors"
      [max]="2"
      [excludes]="['Shariah']"
    ></app-tag-list>
  </div>

  <div class="campaign-card__footer">
    <div class="tw-flex tw-flex-col">
      <!-- Raised amount -->
      <div *ngIf="campaign.campaignStatus != CampaignStatus.PreLive">
        <div class="campaign-card__stat-label p-text-tag">
          {{ 'campaign.raised' | translate }}
        </div>
        <div class="tw-flex tw-items-center tw-gap-2">
          <div class="campaign-card__numbers tw-mr-auto">
            {{ campaign.bankedInAmount | numberSuffix }}
            ({{ calculateRaisedPercentange() }}%)
          </div>

          <!-- Show days left -->
          <div class="campaign-card__dayleft tw-whitespace-nowrap">
            <span
              *ngIf="
                campaign.campaignStatus == CampaignStatus.Live &&
                !campaign.isEnded &&
                calculateDayLeft(campaign.endAt) >= 2
              "
            >
              {{ campaign.endAt | amDifference: today : 'days' : true | number: '1.0-0' }}
              {{
                'campaign.daysLeft_plural'
                  | translate: { 'count': calculateDayLeft(campaign.endAt) }
              }}
            </span>
            <span *ngIf="campaign.campaignStatus == CampaignStatus.Live && campaign.isTomorrow">
              {{ 'campaign.endTomorrow' | translate }}
            </span>
            <span *ngIf="campaign.campaignStatus == CampaignStatus.Live && campaign.isToday">
              {{ 'campaign.endToday' | translate }}
            </span>
            <span
              class="tw-text-secondary-light"
              *ngIf="
                (campaign.campaignStatus != CampaignStatus.Cooling && campaign.isEnded) ||
                campaign.campaignStatus == CampaignStatus.ShareAllotment
              "
            >
              {{ 'campaign.funded' | translate }}
            </span>
            <span *ngIf="campaign.campaignStatus == CampaignStatus.Fail">
              {{ 'campaign.Fail' | translate }}
            </span>
          </div>

          <!-- Cooling off label -->
          <div
            class="campaign-card__status tw-whitespace-nowrap tw-bg-info-light"
            *ngIf="campaign.campaignStatus == CampaignStatus.Cooling"
          >
            {{ 'campaign.' + CampaignStatus[campaign.campaignStatus] | translate }}
          </div>
        </div>
      </div>

      <div
        class="campaign-card__status tw-bg-warning-light"
        *ngIf="campaign.campaignStatus == CampaignStatus.PreLive"
      >
        {{ 'campaign.launchingSoon' | translate }}
      </div>

      <!-- Progress bar -->
      <ng-container
        *ngIf="
          campaign.campaignStatus == CampaignStatus.Live ||
            campaign.campaignStatus == CampaignStatus.Cooling;
          else showDivider
        "
      >
        <div class="tw-my-2.5">
          <mat-progress-bar
            aria-label="progressbar"
            mode="determinate"
            height="10"
            [color]="campaign.raisedPercentage > 100 ? 'success' : ''"
            [value]="calculateProgress(campaign.raisedPercentage)"
          >
          </mat-progress-bar>
        </div>
      </ng-container>

      <ng-template #showDivider>
        <div class="tw-my-3.5">
          <hr class="tw-my-0 tw-border-divider" />
        </div>
      </ng-template>

      <div
        class="campaign-card__stats tw-grid tw-divide-x tw-divide-y-0 tw-divide-solid tw-divide-divider"
        [class.tw-grid-cols-3]="campaign.campaignStatus != CampaignStatus.PreLive"
        [class.tw-grid-cols-2]="campaign.campaignStatus == CampaignStatus.PreLive"
      >
        <!-- Valuation -->
        <div class="campaign-card__stat">
          <div class="campaign-card__stat-label p-text-tag">
            {{ 'campaign.valuation' | translate }}
          </div>
          <div class="campaign-card__numbers tw-text-sm">
            {{
              campaign.valuationAmount
                | numberSuffix
                  : {
                      isSuffix: true,
                      numberFormat: campaign.valuationAmount >= 1000000 ? '1.1-1' : '1.0-1',
                    }
            }}
          </div>
        </div>

        <!-- Target -->
        <div class="campaign-card__stat">
          <div class="campaign-card__stat-label p-text-tag">
            {{ 'campaign.minTarget' | translate }}
          </div>
          <div class="campaign-card__numbers tw-text-sm">
            {{
              campaign.minimumTargetAmount
                | numberSuffix
                  : {
                      isSuffix: true,
                      numberFormat: campaign.minimumTargetAmount >= 1000000 ? '1.1-1' : '1.0-1',
                    }
            }}
          </div>
        </div>

        <!-- Investors -->
        <div class="campaign-card__stat" *ngIf="campaign.campaignStatus != CampaignStatus.PreLive">
          <div class="campaign-card__stat-label p-text-tag">
            {{ 'campaign.investors' | translate }}
          </div>
          <div class="campaign-card__numbers tw-text-sm">
            {{ campaign.investorCount | number }}
          </div>
        </div>
      </div>
    </div>
  </div>
</a>
