import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, tap } from 'rxjs';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit, AfterViewInit {
  @ViewChild('input') input: ElementRef;

  @Input() placeholder: string = 'Search';
  @Input() value: string = '';

  @Output() onSubmit: EventEmitter<string> = new EventEmitter();

  inputValue: string = '';

  ngOnInit() {
    this.inputValue = this.value;
  }

  ngAfterViewInit() {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((event: KeyboardEvent) => {
          this.inputValue = this.input.nativeElement.value;
          this.submit();
        }),
      )
      .subscribe();
  }

  handleChange(newValue: string) {
    this.inputValue = newValue;
  }

  submit() {
    this.onSubmit.emit(this.inputValue);
  }
}
