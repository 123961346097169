<!-- <div class="container-fluid tw-pt-10 tw-pb-10">
    <h1 class="p-text-h2 tw-mb-4">{{ 'dashboard.dashboard' | translate }}</h1>

    <div class="tw-mb-10">
        <div class="tw-border tw-border-divider tw-rounded-lg">
            <div class="tw-flex md:tw-divide-x md:tw-divide-divider max-md:tw-flex-wrap">

                <div class="tw-flex-1 tw-p-3 md:tw-p-5 max-md:tw-flex-[0_0_100%]">
                    <div class="tw-flex max-md:tw-flex-col max-md:tw-items-center tw-gap-5">
                        <a [routerLink]="'/profile/settings'" class="tw-w-20 tw-flex-shrink-0 tw-h-20 ">
                            <img class="tw-w-full tw-h-full tw-object-cover tw-rounded-full tw-border tw-border-solid tw-border-divider"
                                alt="Profile" [src]="(profile && profile?.photoAttachmentUrl) || defaultProfileImage" />
                        </a>
                        <div class="tw-flex-auto tw-w-full max-md:tw-text-center">
                            <h2 class="p-text-h3 !tw-font-normal tw-mb-5">
                                Hi, <a [routerLink]="'/profile/settings'" class="tw-font-medium tw-text-primary">{{ profile?.fullName }}</a>!
                            </h2>
                            <div class="tw-flex tw-gap-8 tw-flex-wrap tw-gap-y-4 max-md:tw-grid max-md:tw-grid-cols-2 tw-text-left"
                                [class]="{
                                    'max-md:!tw-text-center': isPortfolioSummaryCollapsed
                                }">
                                <div [class]="{'max-md:tw-hidden': isPortfolioSummaryCollapsed }">
                                    <span class="overview-label">
                                        {{ 'investment.accountType' | translate }}
                                    </span>
                                    <div class="overview-value !tw-font-normal">
                                        <span class="p-skeleton-bar">{{ "common." + Role[role] | translate }}</span>
                                    </div>
                                </div>

                                <div [class]="{'max-md:tw-hidden': isPortfolioSummaryCollapsed }">
                                    <span class="overview-label">
                                        {{ 'registerInvestor.investorType' | translate }}
                                    </span>
                                    <div class="overview-value !tw-font-normal">
                                        <a [routerLink]="'/profile/settings/investment'" class="p-skeleton-bar p-link-primary">
                                            {{ profile?.investorType ? ('investment.investorType.' + InvestorType[profile?.investorType] | translate) : "-"}}
                                        </a>
                                    </div>
                                </div>

                                <div class="tw-col-span-2" *ngIf="environment.flagToShowPstx">
                                    <span class="overview-label">
                                        {{ 'settings.pstxWallet' | translate }}

                                        <span *ngIf="pstxWallet && isPstxWalletFound" class="tw-px-2 tw-py-0.5 tw-rounded-full tw-bg-secondary-lightest/50 tw-text-black tw-font-semibold tw-text-xxs tw-ml-1">
                                            {{ pstxWallet?.balance | numberSuffix:{ numberFormat: '1.2-2'}  }}
                                        </span>
                                    </span>
                                    <div class="overview-value !tw-font-normal">
                                        <a [routerLink]="'/pstx/account'" class="p-skeleton-bar p-link-primary p-link-underline">{{ (isPstxWalletFound ? 'mysec.topUpWithdraw' : 'mysec.activateNow') | translate }}</a>

                                        <span class="tw-ml-2 tw-text-primary">
                                            <p-icon name="wallet" iconClass="tw-w-5 tw-h-5"></p-icon>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tw-flex-1 tw-p-3 md:tw-p-5"
                    [class.is-loading]="isPortfolioSummaryLoading" 
                    [class]="{
                        'max-md:tw-flex-[0_0_50%]': isPortfolioSummaryCollapsed,
                        'max-md:tw-flex-[0_0_100%]': !isPortfolioSummaryCollapsed
                    }">
                    <div class="p-text-preheader tw-py-1 tw-mb-1.5 md:tw-mb-5">{{ 'common.equity' | translate }}</div>
                    <div class="tw-flex tw-gap-8 tw-text-lg max-md:tw-grid"
                        [class]="{'max-md:tw-grid-cols-2': !isPortfolioSummaryCollapsed}">
                        <div>
                            <span class="overview-label">
                                {{ 'portfolio.totalMarketValue' | translate }}
                                <button type="button" #tooltipTotalMarket="matTooltip" (click)="tooltipTotalMarket.toggle()"
                                    class="btn-transparent tw-align-sub"
                                    matTooltip="{{ 'portfolio.totalMarketValueTooltip' | translate }}">
                                    <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
                                </button>
                            </span>
                            <div class="overview-value !tw-font-normal">
                                <span class="tw-text-lg p-skeleton-bar">{{ portfolioSummary?.totalMarketValue | numberSuffix:{numberFormat:'1.2-2'} }}</span>

                                <div>
                                    <div *ngIf="portfolioSummary?.deltaChanges" class="tw-mt-1 md:tw-mt-2.5 tw-text-xs tw-flex tw-flex-wrap tw-gap-1 p-skeleton-hidden">
                                        <span 
                                            [class.text-success]="portfolioSummary.deltaChanges > 0"
                                            [class.text-primary]="portfolioSummary.deltaChanges < 0">
                                            <span class="tw-mr-1.5">
                                                <i *ngIf="portfolioSummary.deltaChanges > 0" class="fa fa-arrow-up" aria-hidden="true"></i>
                                                <i *ngIf="portfolioSummary.deltaChanges < 0" class="fa fa-arrow-down" aria-hidden="true"></i>
                                            </span>
                                            <span class="tw-mr-1.5 tw-font-medium tw-px-1 tw-py-0.5 tw-rounded-full"
                                                [class]="{
                                                    'tw-bg-primary-light/50': portfolioSummary.deltaChanges < 0,
                                                    'tw-bg-secondary-light/20': portfolioSummary.deltaChanges > 0
                                                }">
                                                {{ portfolioSummary.deltaChanges | number : "1.0-2" }}%
                                            </span>
                                        </span>
                                        <span class="tw-text-black/60">
                                            ({{ portfolioSummary.amountChanges | numberSuffix:{numberFormat: '1.2-2'} }})
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div [class]="{'max-md:tw-hidden': isPortfolioSummaryCollapsed }">
                            <span class="overview-label">
                                {{ 'portfolio.grossInvestment' | translate }}

                                <button type="button" #tooltipGross="matTooltip" (click)="tooltipGross.toggle()"
                                    class="btn-transparent tw-align-sub"
                                    matTooltip="{{ 'portfolio.grossInvestmentTooltip' | translate }}">
                                    <div class="pitchin-icon pitchin-icon-help-circle p-help-icon"></div>
                                </button>
                            </span>
                            <div class="overview-value !tw-font-normal">
                                <span class="tw-text-lg p-skeleton-bar">{{ portfolioSummary?.grossInvestment | numberSuffix: { numberFormat: "1.2-2" } }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="md:tw-flex-[0_0_200px] xl:tw-flex-[0_0_320px] tw-p-3 md:tw-p-5" 
                    [class.is-loading]="isTokenBalanceLoading" 
                    [class]="{
                        'max-md:tw-flex-[0_0_50%]': isPortfolioSummaryCollapsed,
                        'max-md:tw-flex-[0_0_100%]': !isPortfolioSummaryCollapsed
                    }">
                    <div class="p-text-preheader tw-py-1 tw-mb-1.5 md:tw-mb-5">{{ 'common.token' | translate }}</div>
                    <div class="tw-text-lg">
                        <div>
                            <span class="overview-label">
                                {{ 'mysec.totalBalance' | translate }}
                            </span>
                            <div class="overview-value !tw-font-normal">
                                <span class="tw-text-lg p-skeleton-bar">{{ 0 | numberSuffix: { numberFormat: '1.2-2'} }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="md:tw-hidden tw-pt-5 tw-pb-3 tw-px-3 tw-text-center tw-cursor-pointer tw-w-full tw-transition hover:tw-text-body/80" (click)="togglePortfolioSummary()">
                    <span *ngIf="isPortfolioSummaryCollapsed" class="tw-text-sm">{{ 'common.viewAllDetails' | translate}}</span>
                    <span *ngIf="!isPortfolioSummaryCollapsed" class="tw-text-sm">{{ 'common.showLess' | translate}}</span>

                    <div class="tw-opacity-50">
                        <span class="tw-inline-block tw-origin-center tw-transform tw-transition-transform" 
                            [class.tw-rotate-180]="!isPortfolioSummaryCollapsed">
                            <p-icon name="chevron-down"></p-icon>
                        </span>
                    </div>
                </div>  
            </div>
        </div>
    </div>

    <nav class="nav nav-pills" aria-label="investment">
        <a class="nav-item nav-link active" tabIndex="-1">
            <span class="tw-hidden md:tw-block">{{ (environment.flagToShowPstx ? 'Equity Crowdfunding & Secondary Trading Market' : 'Equity Crowdfunding') | translate }}</span>
            <span class="md:tw-hidden">{{ (environment.flagToShowPstx ? 'ECF & PSTX' : 'ECF') | translate }}</span>
        </a>
        <a class="nav-item nav-link" [href]="ieoBaseUrl + '/dashboard/portfolio'">
            <span class="tw-hidden md:tw-block">{{ 'menu.ieoTitle' | translate }}</span>
            <span class="md:tw-hidden">{{ 'common.ieo' | translate }}</span>
        </a>
    </nav>
</div> -->

<div class="container-fluid max-lg:tw-p-0">
  <div class="tw-flex tw-gap-5 max-lg:tw-flex-wrap">
    <div
      class="tw-flex-[0_0_100%] tw-py-10 tw-pt-5 max-lg:tw-pb-5 lg:tw-max-w-[220px] lg:tw-flex-[0_0_220px]"
    >
      <!-- Tabs -->
      <nav
        class="navbar navbar--lite tw-sticky tw-top-20 tw-pt-10 max-lg:tw-hidden"
        aria-label="settings"
      >
        <ul class="navbar__nav navbar__nav--parent">
          <ng-container *ngFor="let tab of tabs">
            <li *ngIf="!tab.show || tab.show(role)" class="nav__item">
              <ng-container *ngTemplateOutlet="link; context: { item: tab }"></ng-container>

              <ul class="navbar__nav">
                <ng-container *ngFor="let subTab of tab.submenus">
                  <li *ngIf="!subTab.show || subTab.show(role)" class="nav__item">
                    <ng-container
                      *ngTemplateOutlet="link; context: { item: subTab }"
                    ></ng-container>
                  </li>
                </ng-container>
              </ul>
            </li>
          </ng-container>
        </ul>
      </nav>

      <div class="tw-px-4 lg:tw-hidden">
        <app-mobile-select-menu
          [icon]="'dashboard'"
          [menu]="tabs"
          [selected]="selectedMenu"
          (valueChanged)="handleMenuChanged($event)"
        >
        </app-mobile-select-menu>
      </div>
    </div>

    <div class="tw-w-full tw-min-w-[0] tw-pb-10 lg:tw-flex-auto lg:tw-py-10">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<ng-template #link let-item="item">
  <a
    *ngIf="item.isExternal"
    class="nav__link tw-flex tw-select-none tw-items-center tw-gap-2.5"
    [href]="item.url"
  >
    <ng-container *ngTemplateOutlet="linkContent; context: { item: item }"></ng-container>
  </a>

  <a
    *ngIf="!item.isExternal"
    class="nav__link tw-flex tw-select-none tw-items-center tw-gap-2.5"
    [routerLink]="item.url"
    [class.active]="selectedMenu === item.name"
  >
    <ng-container *ngTemplateOutlet="linkContent; context: { item: item }"></ng-container>
  </a>
</ng-template>

<ng-template #linkContent let-item="item">
  <p-icon
    *ngIf="item.icon"
    [name]="item.icon"
    [iconClass]="'tw-w-[1.2rem] tw-h-[1.2rem] nav__icon'"
  ></p-icon>
  {{ item.name | translate }}
</ng-template>
