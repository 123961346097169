export const environment = {
    production: true,
    environment: 'PRODUCTION',
    apiServer: 'https://api.equity.pitchin.my/',
    baseUrl: 'https://www.pitchin.my',
    firebase: {
        apiKey: "AIzaSyB5fL1_OGwCaTAjsh2vhxy2RqdK5YiV3a8",
        authDomain: "pitchin-ecf.firebaseapp.com",
        databaseURL: "https://pitchin-ecf.firebaseio.com",
        projectId: "pitchin-ecf",
        storageBucket: "pitchin-ecf.appspot.com",
        messagingSenderId: "340055549276",
        appId: "1:340055549276:web:4d324721e1f241f1db8d99"
    },
    publicAPIKey: "BB8MBkigXd7-L6ploGHhaSUU60HoK4T_Kxt8AVJZ9kuiOsZNDGbp-GMbvvJq-mSCKPpU8g_T0S5y8oVEHpVPOwc",
    googleTagId: "GTM-NC45MCR",
    sentryDsn: "https://420560e3956846bfad663f1116de5478@o4504445196697600.ingest.sentry.io/4504445208494080",
    signalRUrl: "https://signalr.pitchin.my/ctosHub",
    signalRMarketUrl: "https://signalr.pitchin.my/marketsHub",
    kycVersion: "1.0.0",
    cryptoPubKey: "-----BEGIN RSA PUBLIC KEY-----MIGJAoGBAMITRmTe88FiK87o8ATZdlHiLZYWb7At8eiZ/JS/4obUsExBopsJPFZn/lItErwhB73SWOkFPxZgs9IznpEgHs654wiqSiAub+VFpOvm4Whue3kEC7HMnp7Gpyd0ixDl+yDX53NzqKmN+tnj/ifFfPsGwroCwZ2Cpa9Iy1sZ1/3NAgMBAAE=-----END RSA PUBLIC KEY-----",
    prismicRepo: "pitchin-my",
    prismicAcessToken: "MC5aQUE3dUJBQUFDVUFjRThM.77-9HO-_ve-_vR_vv73vv73vv71lKkEx77-9We-_vTjvv70mIHAWCTgA77-9dUTvv73vv71-CO-_vQ",
    ieoBaseUrl: "https://www.pitchin.my/token",
    ieoApiServer: "https://api.digitalassets.pitchin.my/",
    cspSetting: `default-src 'self' *.pitchin.my;
        img-src 'self' data: blob: https://*; 
        media-src 'self';
        font-src 'self' data: application/font-woff; 
        style-src 'self' 'unsafe-inline'; 
        script-src 'self' 'unsafe-eval' https://*.facebook.net https://www.googletagmanager.com https://*.cloudflare.com https://*.posthog.com https://*.cloudflareinsights.com; 
        connect-src 'self' https://*.pitchin.my https://*.linkedin.com https://*.posthog.com https://stats.g.doubleclick.net https://cdn.linkedin.oribi.io https://*.google.com https://*.google.com.my https://*.google-analytics.com https://*.googleadservices.com https://o4504445196697600.ingest.sentry.io https://*.rss2json.com https://firebaseinstallations.googleapis.com https://fcmregistrations.googleapis.com/ wss://signalr.pitchin.my; 
        form-action 'self'; 
        frame-src 'self' https://*.pitchin.my https://www.facebook.com https://www.youtube.com https://player.vimeo.com https://*.doubleclick.net; 
        manifest-src 'self'; 
        worker-src 'self' blob:; 
        frame-ancestors 'self'; 
        script-src-elem 'self' 'unsafe-inline' https://www.googletagmanager.com https://connect.facebook.net https://snap.licdn.com/ https://*.posthog.com https://*.cloudflare.com https://*.cloudflareinsights.com; 
        report-uri https://o4504445196697600.ingest.sentry.io/api/4504445208494080/security/?sentry_key=420560e3956846bfad663f1116de5478&sentry_environment=PRODUCTION; 
        report-to default-sentry-report-group`,
    instrumentationId: '19842a95-0838-459f-9927-c893e57da907',
    flagToShowPstx: true,
    flagToShowDuitNow: true,
    flagToShowTcfWalletManagement: false
};
