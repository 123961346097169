import { Component, EventEmitter, Inject, Input, OnInit, Output, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location, isPlatformBrowser } from '@angular/common';
import { CampaignService } from '../../../core/services/api/campaign.service';
import { ErrorService } from '../../../core/services/error.service';
import { finalize } from 'rxjs/operators';
import { InvestmentModel } from '../../../core/models/investment/investment.model';
import { DefaultProfileImage } from '../../../core/services/constant';
import { InvestmentStatus } from '../../../core/enum/investment-status.enum';
import { Campaign } from '../../../core/models/campaign/campaign';
import { CsvService } from '../../../core/services/csv.service';
import { FieldKey } from '../../../core/models/share/field-key';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { BlockUiService } from '../../../shared/services/blockUi/block-ui.service';

@Component({
  selector: 'app-campaign-investor-list',
  templateUrl: './campaign-investor-list.component.html',
  styleUrls: ['./campaign-investor-list.component.scss'],
})
export class CampaignInvestorListComponent implements OnInit {
  @Input() campaign: Campaign;

  @Output() onInvestorListLoaded = new EventEmitter();

  isPage: boolean = false;
  slug: string;
  campaignTitle: string;
  investments: InvestmentModel[];
  params = {
    currentPage: 1,
    take: 10,
  };

  isLoading: boolean = true;
  isInvitedToInvest: boolean = true;

  total: number = 0;
  defaultProfileImage = DefaultProfileImage;

  InvestmentStatus: typeof InvestmentStatus = InvestmentStatus;

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private campaignService: CampaignService,
    private errorService: ErrorService,
    private csvService: CsvService,
    private translateService: TranslateService,
    private blockUiService: BlockUiService,
    @Inject(PLATFORM_ID) private platform: Object,
  ) {}

  ngOnInit() {
    if (!this.campaign?.id) {
      this.isPage = true;
      this.slug = this.activatedRoute.snapshot.params.slug;
      this.getCampaign();
      return;
    }

    this.getCampaignInvestorList(false);
  }

  getCampaign() {
    this.campaignService.getCampaign(this.slug).subscribe({
      next: (data) => {
        this.campaign = data;

        // Avoid the list being ssr-ed
        if (isPlatformBrowser(this.platform)) {
          if (!data?.isCreator && data?.isPrivate && !data?.isInvited) {
            this.isInvitedToInvest = false;
            this.isLoading = false;
            return;
          }

          this.getCampaignInvestorList(false);
        }
      },
      error: (err) => {
        this.errorService.showError(err);
      },
    });
  }

  getCampaignInvestorList(isReport: boolean) {
    if (isReport) this.blockUiService.open();
    else this.isLoading = true;

    this.campaignService
      .getCampaignInvestorList(
        this.campaign.id,
        this.params.currentPage,
        this.params.take,
        isReport,
      )
      .pipe(
        finalize(() => {
          if (isReport) this.blockUiService.close();
          else this.isLoading = false;
        }),
      )
      .subscribe({
        next: (data) => {
          if (!isReport) {
            this.investments = data.data;
            this.total = data.total;
          } else {
            let jsonData = data.data.map((item) => {
              return {
                fullName: item.user.fullName,
                email: item.user.email,
                phoneNumber:
                  item.user.phoneCountryCode && item.user.phoneNumber
                    ? item.user.phoneCountryCode + item.user.phoneNumber
                    : '-',
                createdAt: moment(item.createdAt).format('D MMM YYYY'),
                pledgedAmount: item.pledgedAmount,
                investmentStatus: this.translateService.instant(
                  'investment.' + InvestmentStatus[item.investmentStatus],
                ),
              };
            });
            this.generateReport(jsonData);
          }

          this.onInvestorListLoaded.emit();
        },
        error: (err) => {
          this.errorService.showError(err);
        },
      });
  }

  generateReport(jsonData: Object[]) {
    let fieldNames: FieldKey[] = [
      { key: 'fullName', title: 'Full Name' },
      { key: 'email', title: 'Email' },
      { key: 'phoneNumber', title: 'Phone Number' },
      { key: 'createdAt', title: 'Invested At' },
      { key: 'pledgedAmount', title: 'Total Invested (RM)' },
      { key: 'investmentStatus', title: 'Status' },
    ];

    this.csvService.downloadFile(jsonData, fieldNames, 'individual-investor');
  }

  back() {
    this.location.back();
  }
}
