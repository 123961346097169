<div [ngClass]="{ 'container-fluid': !fullWidth }">
  <div
    [ngClass]="{ 'tw-flex tw-flex-wrap tw-gap-10 lg:tw-flex-nowrap': column > 1 }"
    class="tw-py-10"
    [class.tw-min-h-[60vh]]="headerType === 'h1'"
    [class.!tw-pt-0]="headerType === 'h2'"
  >
    <!-- Main Content -->
    <div class="tw-min-w-0 tw-flex-auto">
      <div class="tw-mb-5 tw-flex tw-flex-wrap tw-items-start tw-gap-5 md:tw-flex-nowrap">
        <div>
          <product-badge *ngIf="productBadge" [name]="productBadge"></product-badge>

          <h1 *ngIf="headerType === 'h1'" class="p-text-h2 tw-mb-0">{{ name | translate }}</h1>
          <h2 *ngIf="headerType === 'h2'" class="p-text-h3 tw-mb-0">{{ name | translate }}</h2>
          <div *ngIf="description" class="tw-mt-1">{{ description | translate }}</div>
        </div>

        <!-- Page actions next to name -->
        <ng-content select="[action]"></ng-content>
      </div>

      <ng-content></ng-content>
    </div>

    <!-- Secondary -->
    <div class="tw-flex-auto lg:tw-w-[320px] lg:tw-flex-none">
      <ng-content select="[secondary]"></ng-content>
    </div>
  </div>
</div>
